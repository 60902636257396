import { environment } from "../../../environments/environment";

const apiPath = environment.apiPath;

export const APIConstant = {
    basePath: apiPath,
    finance: `${apiPath}/finance`,
    getFeeHeads: `${apiPath}/finance/fee-setup/heads`,
    getFeeTerm: `${apiPath}/finance/fee-setup/terms`,
    financeFees: `${apiPath}/finance/fees`,
    payment: `${apiPath}/finance/fees/payments`,
    receipt: `${apiPath}/finance/fees/receipt`,
    concession: `${apiPath}/finance/concession-setup`,
    bankAccount: `${apiPath}/finance/bankAccounts/school`,
    getConcession: `${apiPath}/finance/concession-setup`,
    getPaymentGateway: `${apiPath}/pg/payu/config`,
    salaryComponents: `${apiPath}/finance/salary/components`,
    salarySetups: `${apiPath}/finance/salary/setup`,
    salarySetupsMyProfile: `${apiPath}/finance/salary/setup/myProfile`,
    salaryStatement: `${apiPath}/finance/salary/statement`,
    salaryStatementMyProfile: `${apiPath}/finance/salary/statement/myProfile`,
    payrollGenerate: `${apiPath}/finance/salary/payroll`,
    categoryMapping: `${apiPath}/finance/fees/category-mapping`,
    feeSetup: `${apiPath}/finance/fee-setup`,
    copy: `${apiPath}/finance/fee-setup/copy`,
    feeSummary: `${apiPath}/finance/fees/fee-summary`,
    paymentsSummary: `${apiPath}/finance/fees/payments-summary`,
    expensesSummary: `${apiPath}/finance/expenses/summary`,
    feeSummaryByDueDate: `${apiPath}/finance/fees/summary/by-due-date`,
    feeDueSummaryByDueDate: `${apiPath}/finance/fees/due-summary/by-due-date`,
    dateWiseFeePaymentSummary: `${apiPath}/finance/fees/datewise-payments-summary`,
    salaryPaymentHistory: `${apiPath}/finance/salary/paymentHistory`,
    salaryPaymentHistoryMyProfile: `${apiPath}/finance/salary/paymentHistory/myProfile`,
    feeDue: `${apiPath}/finance/fees/due-report`,
    bankStatements: `${apiPath}/finance/bankAccounts/statement`,
    balance: `${apiPath}/finance/bankAccounts/balance`,
    purchasePayment: `${apiPath}/finance/purchases/payments`,
    purchaseTotal: `${apiPath}/finance/purchases/total`,
    purchaseDueTotal: `${apiPath}/finance/purchases/dues/total`,
    otherExpenseIncome: `${apiPath}/finance/otherIncomeAndExpense`,
    purchases: `${apiPath}/finance/purchases`,
    suppliers: `${apiPath}/finance/suppliers`,
    bankAccountSetup: `${apiPath}/finance/school-bank-account`,
    feeReceiptTemplate: `${apiPath}/finance/feeReceiptTemplates`,
    paymentNotification: `${apiPath}/notifications/fee-payment`,
    concessionSetup: `${apiPath}/finance/concession/setup`,
    unclearedFeePayment: `${apiPath}/finance/fees/byClearanceStatus`,
    clearPayments: `${apiPath}/finance/fees/clearPayments`,
    houseMappings: `${apiPath}/academics/house-mapping`,
    outstandingPayments: `${apiPath}/finance/salary/outstanding-payments`,
    salaryPayments: `${apiPath}/finance/salary/payments`,
    paySlip: `${apiPath}/finance/salary/payslip`,
    getallemployees: `${apiPath}/hr/employees/all`,
    paymentReceivedByList: `${apiPath}/finance/fees/paymentsReceivedByList`,
    feeCollectionByCasierSummary: `${apiPath}/finance/fees/repots/byCashier/byPaymentMethod/summary`,
    feeCollectionByCasier: `${apiPath}/finance/fees/repots/byCashier/byPaymentMethod`,
    feeConcessionSummary: `${apiPath}/finance/fees/reports/byConcessionCode/summary`,
    feeConcessionCode: `${apiPath}/finance/fees/reports/byConcessionCode`,
    otherIncomeExpenseCategories: `${apiPath}/finance/otherIncomeAndExpense/categories`,
    otherIncomeExpenseSubCategories: `${apiPath}/finance/otherIncomeAndExpense/subCategory`,
    getFeeHeadSummary: `${apiPath}/finance/fees/reports/byFeeHead/summary/date-range`,
    getFeeHeadCollection: `${apiPath}/finance/fees/reports/byFeeHead/date-range`,
    feeStatusSummaryReport: `${apiPath}/finance/fees/reports/byClearanceStatus`,
    studentClasses:`${apiPath}/students/classes`,
    currentAcademicYear: `${apiPath}/academics/academic-year-setup/current-academic-year`,
    feeCollectionSummary: `${apiPath}/finance/fees/fee-collection-summary`,
    feeDueYearWise: `${apiPath}/finance/fees/year-wise-due-report`,
    razorPayConfig: `${apiPath}/pg/razorpay/config`,
    allAtomTransactionsByCustId:`${apiPath}/pg/atom-transactions/by-student`,
    allOnepayConfig:`${apiPath}/pg/onepay-config/all`,
    productConfigById:`${apiPath}/pg/onepay-productconfig/all`,
    feeHeadById:`${apiPath}/pg/onepay-feeheadconfig/all`,
    onepayConfig:`${apiPath}/pg/onepay-config`,
    productconfig:`${apiPath}/pg/onepay-productconfig`,
    feeheadConfig:`${apiPath}/pg/onepay-feeheadconfig`,
    postInfo:`${apiPath}/students/search-data`,
    empSearchData:`${apiPath}/hr/employees/search-data`,
    // atom pg configurations.
    atomPgSetUp:`${apiPath}/pg/atom/config`,
    atomProduct:`${apiPath}/pg/atom/product-config/by-school-code`,
    deleteAtomPgSetUp:`${apiPath}/pg/atom/config`,
    atomProductConfig:`${apiPath}/pg/atom/product-config`,
    importFee: `${apiPath}/finance/fees/import-fees-excel`,
    importPaymeents: `${apiPath}/finance/fees/import-transaction-wise-fee-payments-excel`,
    getImportData: `${apiPath}/students/data-import-tasks/by-school`,
    importStudentWise: `${apiPath}/finance/fees/import-fee-payments-excel`


};

export const PublicAPI = [];
