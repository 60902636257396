import { Injectable } from "@angular/core";
import { CRUDService, BaseService, CommonUtility } from "projects/common/src/public-api";
import {
    SalaryStatementData, BankAccount, Fee, FeePayment, Finance, Concession,
    PaymentGateway, FeeHead, TermsList, CasierSummary, APIPagedResponse,
    AtomPg,
    AtomProduct
} from 'models';
import { Observable } from 'rxjs';
import { APIConstant } from "../constant";
import { ConcessionSummary } from "projects/model/concession";

@Injectable({
    providedIn: "root",
})
export class FinanceService extends CRUDService<Finance> {
    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.financeFees + "/");
    }

    addFeeHeads(classroomData: FeeHead): Observable<any> {
        return this.baseService.post(`${APIConstant.getFeeHeads}`, classroomData);
    }

    updateFeeHeads(classroomData: FeeHead): Observable<any> {
        return this.baseService.put(`${APIConstant.getFeeHeads}`, classroomData);
    }

    deleteFeeHeads(id: number): Observable<any> {
        const params = CommonUtility.convertObjectToParams({
            feeHeadSetupId: id,
        });
        return this.baseService.delete(`${APIConstant.getFeeHeads}?${params}`);
    }

    addTerm(classroomData: TermsList): Observable<any> {
        return this.baseService.post(`${APIConstant.getFeeTerm}`, classroomData);
    }

    getStudentOutstandingFees(data: any): Observable<Fee[]> {
        const params = CommonUtility.convertObjectToParams(data);
        return this.baseService.get<Fee[]>(`${APIConstant.financeFees}?${params}`);
    }

    getStudentFeePaymentHistory(data: any): Observable<FeePayment[]> {
        const params = CommonUtility.convertObjectToParams(data);
        return this.baseService.get<FeePayment[]>(`${APIConstant.payment}?${params}`);
    }

    addStudentFee(studentProfileId: number, feeDetail: any): Observable<any> {
        const params = CommonUtility.convertObjectToParams({ studentProfileId });
        return this.baseService.post<any>(`${APIConstant.financeFees}?${params}`, feeDetail);
    }

    importStudentFee(studentProfileId: number, feeDetail: any): Observable<any> {
        return this.baseService.post<any>(`${APIConstant.financeFees}/import`, feeDetail);
    }

    updateStudentFee(studentFeeId: number, feeDetail: any): Observable<any> {
        return this.baseService.put<any>(`${APIConstant.financeFees}/${studentFeeId}`, feeDetail);
    }

    deleteStudentFee(studentFeeId: number): Observable<any> {
        return this.baseService.delete<any>(`${APIConstant.financeFees}/${studentFeeId}`);
    }

    getFeeConcessionSetup(feeHeadSetupId: number): Observable<any[]> {
        const params = CommonUtility.convertObjectToParams({ feeHeadSetupId });
        return this.baseService.get<any[]>(`${APIConstant.concessionSetup}?${params}`);
    }

    getSchoolBankAccounts(): Observable<BankAccount[]> {
        return this.baseService.get<BankAccount[]>(`${APIConstant.bankAccount}`);
    }

    addFeePayment(paymentDetails: any, sms: any): Observable<any> {
        return this.baseService.post<any>(`${APIConstant.payment}?sendSms=${sms}`, paymentDetails);
    }

    updateFeePayment(paymentDetails: any, sms: any): Observable<any> {
        return this.baseService.put<any>(`${APIConstant.payment}?sendSms=${sms}`, paymentDetails);
    }

    cancelStudentFeePayment(invoiceNumber: number): Observable<any[]> {
        const params = CommonUtility.convertObjectToParams({ invoiceNumber });
        return this.baseService.delete<any[]>(`${APIConstant.payment}?${params}`);
    }

    getFeeReceipt(invoiceNumber: number): Observable<any> {
        const headers = new Headers();
        headers.set('Content-Type', 'text/html; charset=UTF-8');
        const params = CommonUtility.convertObjectToParams({ invoiceNumber });
        return this.baseService.getWithHeader(`${APIConstant.receipt}?${params}`, { headers, responseType: 'text' });
    }

    updateTerm(classroomData: TermsList): Observable<any> {
        return this.baseService.put(`${APIConstant.getFeeTerm}`, classroomData);
    }

    getFeeHeadsTerms(id: number): Observable<any> {
        // return this.baseService.get(`${APIConstant.classrooms}/dropdown`);
        const params = CommonUtility.convertObjectToParams({
            feeHeadSetupId: id,
        });
        return this.baseService.get(`${APIConstant.getFeeTerm}?feeHeadSetupId=${id}`);
    }

    getConcession(): Observable<any> {
        return this.baseService.get(`${APIConstant.concessionSetup}`);
    }

    addConcession(concessionRequestData: Concession): Observable<any> {
        return this.baseService.post(`${APIConstant.concessionSetup}`, concessionRequestData);
    }

    updateConcession(concessionRequestData: Concession): Observable<any> {
        return this.baseService.post(`${APIConstant.concessionSetup}`, concessionRequestData);
    }

    deleteConcession(concessionId: number): Observable<any> {
        return this.baseService.delete(`${APIConstant.concessionSetup}?concessionId=${concessionId}`);
    }

    getPaymentGateway(): Observable<any> {
        // return this.baseService.get(`${APIConstant.classrooms}/dropdown`);
        return this.baseService.get(`${APIConstant.getPaymentGateway}`);
    }
    // Atom pg Set Up
    getAtomConfig(): Observable<any> {
        return this.baseService.get(`${APIConstant.atomPgSetUp}`);
    }

    addAtomConfig(atomRequestData:AtomPg): Observable<any>{
        return this.baseService.post(`${APIConstant.atomPgSetUp}`,atomRequestData);
    }
    updateAtomConfig( atomRequestData: AtomPg): Observable<any>{
        return this.baseService.put(`${APIConstant.atomPgSetUp}`,atomRequestData);
    }
    deleteAtomConfig(id:number): Observable<any> {
        return this.baseService.delete(`${APIConstant.atomPgSetUp}?id=${id}`)
    }
    // atom Products
    getAtomProduct(): Observable<any>{
        return this.baseService.get(`${APIConstant.atomProduct}`)
    }

    addAtomProduct(productRequestData: AtomProduct[]): Observable<any> {
        return this.baseService.post(`${APIConstant.atomProductConfig}`, productRequestData);
    }
    
  
    updateAtomProduct (productRequestData:AtomProduct[]): Observable<any> {
        return this.baseService.put(`${APIConstant.atomProductConfig}`,productRequestData)
    }
    deleteAtomProduct(): Observable<any>{
        return this.baseService.delete(`${APIConstant.atomProductConfig}`);
    }

    addPaymentGateway(paymentGatewayRequestData: PaymentGateway): Observable<any> {
        return this.baseService.post(`${APIConstant.getPaymentGateway}`, paymentGatewayRequestData);
    }

    updatePaymentGateway(paymentGatewayRequestData: PaymentGateway): Observable<any> {
        return this.baseService.post(`${APIConstant.getPaymentGateway}`, paymentGatewayRequestData);
    }

    deletePaymentGateway(id: number): Observable<any> {
        return this.baseService.delete(`${APIConstant.getPaymentGateway}?id=${id}`);
    }

    getStudentFeeSummaryData(reqBody: object): Observable<any> {
        return this.baseService.post(`${APIConstant.feeSummary}?includeDroppedStudents=TRUE`, reqBody);
    }
    getStudentFeeSummary(reqBody: object): Observable<any> {
        return this.baseService.post(`${APIConstant.feeSummary}?includeDroppedStudents=FALSE`, reqBody);
    }

    getStudentFeeDue(reqBody: object): Observable<any> {
        return this.baseService.post(`${APIConstant.feeDue}`, reqBody);
    }

    getFeeSummaryByDueDate() {
       
        return this.baseService.get(`${APIConstant.feeSummaryByDueDate}`);
    }

    getFeeDueSummaryByDueDate() {
        return this.baseService.get(`${APIConstant.feeDueSummaryByDueDate}`);
    }

    getFeeDueYearWise(reqBody: any): Observable<any> {
        return this.baseService.post(`${APIConstant.feeDueYearWise}`, reqBody);
    }

    getDateWiseFeePaymentSummary(data: any) {
        const reqParams = CommonUtility.convertObjectToParams(data);
        return this.baseService.get(`${APIConstant.dateWiseFeePaymentSummary}?${reqParams}`);
    }

    getFeeHeadWiseCollectionSummary() {
        return this.baseService.get(`${APIConstant.paymentsSummary}`);
    }

    getCurrentWeekWiseFeePaymentSummary(data: any) {
        const reqParams = CommonUtility.convertObjectToParams(data);
        return this.baseService.get(`${APIConstant.paymentsSummary}?${reqParams}`);
    }

    getExpenseSummary() {
        return this.baseService.get(`${APIConstant.expensesSummary}`);
    }   

    getTotalPurchaseAmount() {
        return this.baseService.get(`${APIConstant.purchaseTotal}`);
    }

    getTotalPurchaseDueAmount() {
        return this.baseService.get(`${APIConstant.purchaseDueTotal}`);
    }

    purchasePayementHistory(params: any) {
        const data = CommonUtility.convertObjectToParams(params);
        return this.baseService.get(`${APIConstant.purchasePayment}?${data}`);
    }

    sendPaymentNotification(reqBody: object): Observable<any> {
        return this.baseService.post(`${APIConstant.paymentNotification}`, reqBody);
    }

    getUnclearedFeePayments(data: any, reqBody: any): Observable<any> {
        const params = CommonUtility.convertObjectToParams(data);
        return this.baseService.post(`${APIConstant.unclearedFeePayment}?${params}`, reqBody);
    }

    clearPayments(date: any, params: any): Observable<any> {
        return this.baseService.post(`${APIConstant.clearPayments}?clearedDate=${date}`, params);
    }

    updateStudentHouseMapping(categoryMapping: any): Observable<any> {
        return this.baseService.put<any>(`${APIConstant.houseMappings}`, categoryMapping);
    }

    getStudentHouseMappingByProfileId(studentProfileId: number): Observable<any> {
        const params = CommonUtility.convertObjectToParams({ studentProfileId });
        return this.baseService.get<any>(`${APIConstant.houseMappings}?${params}`);
    }

    getOutStandingSalaryPayment(payrollMonth: any) {
        const params = CommonUtility.convertObjectToParams({ payrollMonth });
        return this.baseService.get<any>(`${APIConstant.outstandingPayments}?${params}`);
    }

    addEmployeeSalaryPayment(params: any) {
        return this.baseService.post(`${APIConstant.salaryPayments}`, params);
    }

    getAllEmployees(): Observable<any> {
        return this.baseService.get(`${APIConstant.getallemployees}`);
    }

    getCasierList(): Observable<any> {
        return this.baseService.get(`${APIConstant.paymentReceivedByList}`);
    }

    getFeeCollectionsByCasierSummary(data) {
        const params = CommonUtility.convertObjectToParams({ ...data });
        console.log(params)
        return this.baseService.get<CasierSummary>(`${APIConstant.feeCollectionByCasierSummary}?${params}`);
    }

    getFeeCollectionsByCasier(data: any) {
        const params = CommonUtility.convertObjectToParams(data);
        return this.baseService.get<APIPagedResponse<any>>(`${APIConstant.feeCollectionByCasier}?${params}`);
    }

    getFeeHeadSummary = (data: any) => {
        const params = CommonUtility.convertObjectToParams(data);
        return this.baseService.get(`${APIConstant.getFeeHeadSummary}?${params}`);
    }
    getFeeHeadCollection = (data: any) => {
        const params = CommonUtility.convertObjectToParams(data);
        return this.baseService.get(`${APIConstant.getFeeHeadCollection}?${params}`);
    }

    getFeeConcessionSummary(academicYear: string) {
        const params = CommonUtility.convertObjectToParams({ academicYear });
        return this.baseService.get<ConcessionSummary>(`${APIConstant.feeConcessionSummary}?${params}`);
    }

    getFeeDetailsByConcessionCode(data: any): Observable<any> {
        const params = CommonUtility.convertObjectToParams(data);
        return this.baseService.get(`${APIConstant.feeConcessionCode}?${params}`);
    }

    getSupplierList() {
        return this.baseService.get(`${APIConstant.suppliers}`);
    }
    getOtherIncomeExpenseCategory(showInactive: any): Observable<any> {
        const reqParams = CommonUtility.convertObjectToParams({ showInactive });
        return this.baseService.get(`${APIConstant.otherIncomeExpenseCategories}?${reqParams}`);
    }

    addOtherIncomeExpenseCategory(categoryData: any): Observable<any> {
        return this.baseService.post(`${APIConstant.otherIncomeExpenseCategories}`, categoryData);
    }

    updateOtherIncomeExpenseCategory(categoryData: any): Observable<any> {
        return this.baseService.put(`${APIConstant.otherIncomeExpenseCategories}`, categoryData);
    }

    addOtherIncomeExpenseSubCategory(categoryData: any): Observable<any> {
        return this.baseService.post(`${APIConstant.otherIncomeExpenseSubCategories}`, categoryData);
    }

    updateOtherIncomeExpenseSubCategory(categoryData: any): Observable<any> {
        return this.baseService.put(`${APIConstant.otherIncomeExpenseSubCategories}`, categoryData);
    }

    feeStatusSummaryReport(data: any): Observable<any> {
        const params = CommonUtility.convertObjectToParams(data);
        return this.baseService.get<any>(`${APIConstant.feeStatusSummaryReport}?${params}`);
    }

    getStudentClasses(studentProfileId: number) {
        const params = CommonUtility.convertObjectToParams({ studentProfileId });
        return this.baseService.get<any>(`${APIConstant.studentClasses}?${params}`);
    }

    getCurrentAcademicYear() {
        return this.baseService.get(`${APIConstant.currentAcademicYear}?`);
    }

    getFeeCollectionSummary(reqBody: any): Observable<any> {
        return this.baseService.get(`${APIConstant.feeCollectionSummary}?fromDate=${reqBody.fromDate}&toDate=${reqBody.toDate}`);
    }

    getRazorPayData() {
        return this.baseService.get(`${APIConstant.razorPayConfig}`);
    }

    addRazorPayConfig(params: any) {
        return this.baseService.post(`${APIConstant.razorPayConfig}`, params);
    }

    updateRazorPayConfig(params: any) {
        return this.baseService.put(`${APIConstant.razorPayConfig}`, params);
    }

    deleteRazonPayConfig(id: any) {
        return this.baseService.delete(`${APIConstant.razorPayConfig}?id=${id}`);
    }

    getAllAtomTransactionsByCustID(studentProfileId:number,academicYear:string)
    {
        return this.baseService.get(`${APIConstant.allAtomTransactionsByCustId}?studentProfileId=${studentProfileId}&academicYear=${academicYear} `)
    }

    getAllOnepayConfig()
    {
        return this.baseService.get(`${APIConstant.allOnepayConfig}`)
    }

    getProductConfigById(id)
    {
        return this.baseService.get(`${APIConstant.productConfigById}?merchantId=${id}`)
    }
    getFeeHeadById(id)
    {
        return this.baseService.get(`${APIConstant.feeHeadById}?productId=${id}`)
    }
    saveMerchant(merchant)
    {
        return this.baseService.post(`${APIConstant.onepayConfig}`, merchant)
    }
    updateMerchant(merchant)
    {
        return this.baseService.put(`${APIConstant.onepayConfig}`, merchant)
    }
    deleteMerchant(id)
    {
        return this.baseService.delete(`${APIConstant.onepayConfig}?configid=${id}`)
    }
    saveProductConfig(merchant)
    {
        return this.baseService.post(`${APIConstant.productconfig}`, merchant)
    }
    updateProductConfig(merchant)
    {
        return this.baseService.put(`${APIConstant.productconfig}`, merchant)
    }
    deleteProductConfig(id)
    {
        return this.baseService.delete(`${APIConstant.productconfig}?configId=${id}`)
    }
    saveFeeHeadConfig(merchant)
    {
        return this.baseService.post(`${APIConstant.feeheadConfig}`, merchant)
    }
    updateFeeHeadConfig(merchant)
    {
        return this.baseService.put(`${APIConstant.feeheadConfig}`, merchant)
    }
    deleteFeeHeadConfig(id)
    {
        return this.baseService.delete(`${APIConstant.feeheadConfig}?configid=${id}`)
    }
    basicPostInfo(searchStudentProfileId: number) {
        return this.baseService.post(`${APIConstant.postInfo}?searchStudentProfileId=${searchStudentProfileId}`, null)
    }
    importStudentsFee(params: any, data: any) {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.post(`${APIConstant.importFee}?${query}`, data);
    }
    
    importFeePaymentsTransactions(params: any, data: any) {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.post(`${APIConstant.importPaymeents}?${query}`, data);
    }
    getImportedTasks(params: any) {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.get(`${APIConstant.getImportData}?${query}`);
    }
    ImportByStudentWise(params:any,data:any){
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.post(`${APIConstant.importStudentWise}?${query}`,data)
    }

    getImportedfeePaymentTasks(params: any) {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.get(`${APIConstant.getImportData}?${query}`);
    }
}